import { useSkin } from '@orus.eu/pharaoh'
import type { StripeElementsOptions } from '@stripe/stripe-js'
import { useMemo } from 'react'
import { useStripeAppearance } from './stripe-appearance'

export function useStripeOptions(stripeClientSecret: string): StripeElementsOptions {
  const appearance = useStripeAppearance()
  const baseFontFamilyUrl = useSkin().stripe.fontFamilyUrl

  return useMemo(
    () => ({
      clientSecret: stripeClientSecret,
      appearance,
      fonts: [{ cssSrc: baseFontFamilyUrl }],
    }),
    [appearance, baseFontFamilyUrl, stripeClientSecret],
  )
}
